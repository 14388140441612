<template>
  <div class="ui-slider">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ui-slider',

  props: {
    value: {
      required: false,
      default: null,
    },
  },

  data() {
    return {
      selectedIndex: -1,
      haltListener: false,
    };
  },

  watch: {
    value: {
      // immediate: true,  // No puede ser immediate porque no existe $children en ese punto.  En mounted() si :)
      handler(newValue) {
        this.selectSlide(newValue);
      },
    },
  },

  mounted() {
    this.selectSlide(this.value);
    this.$el.addEventListener('scroll', this.onContainerScroll, false);
  },

  destroyed() {
    this.$el.removeEventListener('scroll', this.onContainerScroll, false);
  },

  methods: {
    onContainerScroll(event) {
      if (this.haltListener) {
        return;
      }

      let visibleIndex = Math.round(
        event.target.scrollLeft / event.target.offsetWidth
      );

      if (visibleIndex != this.selectedIndex) {
        this.selectedIndex = visibleIndex;
        this.$emit('input', this.$children[visibleIndex].value);
      }
    },

    selectSlide(value) {
      let foundIndex = this.$children.findIndex((c) => c.value == value);
      if (foundIndex == -1 || foundIndex == this.selectedIndex) {
        return;
      }

      this.selectedIndex = foundIndex;
      this.scrollToIndex(this.selectedIndex);
    },

    scrollToIndex(index) {
      if (typeof this.$children[index] == 'undefined') {
        return;
      }

      let elChild = this.$children[index].$el;

      this.haltListener = true;
      this.$el.scrollTo({
        top: 0,
        left: elChild.offsetLeft,
        behavior: 'smooth',
      });
      setTimeout(() => (this.haltListener = false), 600);
    },
  },
};
</script>

<style lang="scss">
.ui-slider {
  position: relative;
  overflow: auto;

  display: flex;
  flex-wrap: nowrap;

  // Scroll snap
  -webkit-scroll-snap-type: mandatory;
  -webkit-scroll-snap-points-x: repeat(100%);
  scroll-snap-type: mandatory;
  scroll-snap-points-y: repeat(100%);
  scroll-snap-type: x mandatory;
  scroll-padding: 0;

  .ui-slider-item {
    min-width: 100%;
    width: 100%;

    // Scroll snap
    scroll-snap-align: center;
    -webkit-scroll-snap-coordinate: 50% 50%;
  }
}
</style>